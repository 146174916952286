import { FunctionalComponent } from "preact";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";
import QuillEditor from "./quill-editor";

import {
  Select,
  TextField,
  FormControlLabel,
  RadioGroup,
  Switch,
  Checkbox,
  Autocomplete,
} from "@mui/material";

interface InputProps {
  name: string;
  type?: string;
  label?: string;
  children?: FunctionalComponent[] | JSX.Element[];
  [key: string]: unknown;
}

const ErrorMessages: FunctionalComponent = ({ children }) => (
  <span className="text-red-500 text-sm">{children}</span>
);

export const QuestionLabel: FunctionalComponent = ({ children, ...props }) => (
  <p className="text-gray-500 mt-2 mb-4" {...props}>
    {children}
  </p>
);

export const TextFieldInput: FunctionalComponent<InputProps> = ({
  name,
  type = "text",
  ...props
}) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className="flex flex-col my-2">
      <TextField
        variant="outlined"
        error={!!fieldState.error}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value ? (field.value as string) : ""}
        name={field.name}
        type={type}
        {...props}
      />
      {fieldState.error && (
        <ErrorMessages>{fieldState.error.message}</ErrorMessages>
      )}
    </div>
  );
};

export const NumberFieldInput: FunctionalComponent<InputProps> = ({
  name,
  ...props
}) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div className="flex flex-col my-2">
      <TextField
        variant="outlined"
        error={!!fieldState.error}
        onChange={({ target }: { target: HTMLInputElement }) => {
          field.onChange(parseInt(target.value));
        }}
        onBlur={field.onBlur}
        value={typeof field.value === "number" ? field.value : ""}
        name={field.name}
        type="number"
        {...props}
      />
      {fieldState.error && (
        <ErrorMessages>{fieldState.error.message}</ErrorMessages>
      )}
    </div>
  );
};

export const RadioGroupInput: FunctionalComponent<InputProps> = ({
  name,
  children,
  ...props
}) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
  });

  if (!children?.length) {
    return null;
  }

  return (
    <div className="flex flex-col my-2">
      <RadioGroup
        value={field.value ? (field.value as string) : ""}
        name={field.name}
        onChange={field.onChange}
        {...props}
      >
        {children}
      </RadioGroup>
      {fieldState.error && (
        <ErrorMessages>{fieldState.error.message}</ErrorMessages>
      )}
    </div>
  );
};

export const DropDownInput: FunctionalComponent<InputProps> = ({
  name,
  children,
  ...props
}) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
  });

  if (!children?.length) {
    return null;
  }

  return (
    <div className="flex flex-col my-2">
      <Select
        value={field.value ? (field.value as string) : ""}
        name={field.name}
        onChange={field.onChange}
        {...props}
      >
        {children}
      </Select>
      {fieldState.error && (
        <ErrorMessages>{fieldState.error.message}</ErrorMessages>
      )}
    </div>
  );
};

export const SwitchInput: FunctionalComponent<InputProps> = ({
  name,
  label,
}) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
  });

  const element = (
    <Switch
      checked={field.value ? true : false}
      name={field.name}
      onChange={({ target }: { target: HTMLInputElement }) => {
        field.onChange(target ? target.checked : false);
      }}
    />
  );

  return (
    <div className="flex flex-col my-2">
      {label ? (
        <FormControlLabel
          className="text-gray-500"
          required
          control={element}
          label={label}
        />
      ) : (
        element
      )}
      {fieldState.error && (
        <ErrorMessages>{fieldState.error.message}</ErrorMessages>
      )}
    </div>
  );
};

interface CheckboxInputProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues, unknown>;
  label: string;
  name: Path<TFieldValues>;
}

export const CheckboxInput = <TFieldValues extends FieldValues>({
  control,
  label,
  name,
}: CheckboxInputProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, onBlur, onChange, value } }) => {
        return (
          <FormControlLabel
            className="text-gray-500 col-span-1"
            control={
              <Checkbox
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                checked={typeof value === "boolean" ? value : false}
              />
            }
            label={label}
          />
        );
      }}
    />
  );
};

export const RichEditorInput: FunctionalComponent<InputProps> = ({
  name,
  ...props
}) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
  });

  const parser = new DOMParser();

  return (
    <div className="flex flex-col my-2 h-full">
      <QuillEditor
        value={field.value ? (field.value as string) : ""}
        onChange={(value) => {
          const parsedHtml = parser.parseFromString(value, "text/html");
          const textContent = parsedHtml.body.textContent || "";
          field.onChange(textContent ? value : "");
        }}
        {...props}
      />
      {fieldState.error && (
        <ErrorMessages>{fieldState.error.message}</ErrorMessages>
      )}
    </div>
  );
};

interface AutoCompleteComboBoxProps extends InputProps {
  options: string[];
  label: string;
}

export const AutoCompleteComboBox: FunctionalComponent<
  AutoCompleteComboBoxProps
> = ({ name, options, label, ...props }) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
  });

  if (!options?.length) {
    return null;
  }

  return (
    <div className="flex flex-col my-2">
      <Autocomplete
        disablePortal
        options={options}
        {...props}
        value={field.value ? (field.value as string) : null}
        getOptionLabel={(option: string) => option}
        isOptionEqualToValue={(option: string, value: string) =>
          option === value
        }
        name={field.name}
        onChange={(_, option) => field.onChange(option)}
        renderInput={(params) => (
          <TextField error={fieldState.error} {...params} label={label} />
        )}
      />
      {fieldState.error && (
        <ErrorMessages>{fieldState.error.message}</ErrorMessages>
      )}
    </div>
  );
};
