import { AlpineComponent } from "alpinejs";

// GA appears to be very fussy about how this function is defined
// which is why we're breaking all these rules.

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function gtag(..._args: unknown[]) {
  // eslint-disable-next-line prefer-rest-params, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
  window.dataLayer?.push(arguments as any);
}

export default (
  id: unknown,
): AlpineComponent<Record<string | symbol, unknown>> => {
  if (typeof id !== "string") {
    return {};
  }

  const j = document.createElement("script");

  j.async = true;
  j.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

  document.body.appendChild(j);

  window.dataLayer = window.dataLayer ?? [];

  gtag("js", new Date());
  gtag("config", id, { debug_mode: true });

  return {};
};
