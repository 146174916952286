import { AlpineComponent } from "alpinejs";

interface State extends Record<string | symbol, unknown> {
  status: "ready" | "uploading" | "success" | "failure";
  content: string | null;
}

export default (): AlpineComponent<State> => ({
  content: null,
  drop(event: unknown) {
    if (!(event instanceof DragEvent)) {
      return;
    }

    const input = this.$refs["form"]?.querySelector("input[type='file']");

    if (!(input instanceof HTMLInputElement)) {
      return;
    }

    input.files = event.dataTransfer?.files ?? null;
    input.dispatchEvent(new Event("change"));
  },
  init() {
    this.content = this.$refs["content"]?.innerHTML ?? null;
  },
  status: "ready",
  submit() {
    const form = this.$refs["form"];

    if (!(form instanceof HTMLFormElement)) {
      return;
    }

    const data = new FormData(form);

    const xhr = new XMLHttpRequest();

    xhr.onloadstart = () => {
      this.status = "uploading";
    };

    xhr.onload = () => {
      setTimeout(() => {
        this.status = "success";
        this.content = xhr.responseText;

        setTimeout(() => {
          this.status = "ready";
        }, 2000);
      }, 1000);
    };

    xhr.onerror = () => {
      setTimeout(() => {
        this.status = "failure";
      }, 1000);
    };

    xhr.open(form.method, form.action);
    xhr.send(data);
  },
  ready() {
    return this.status === "ready";
  },
  uploading() {
    return this.status === "uploading";
  },
  success() {
    return this.status === "success";
  },
  failure() {
    return this.status === "failure";
  },
});
