import { AlpineComponent } from "alpinejs";
import { call } from "../fetch";
import { z } from "zod";

const ValuationResponse = z.object({
  current_property_value: z.number(),
  potential_property_value: z.number(),
});

interface State extends Record<string | symbol, unknown> {
  fetching: boolean;
  fetch_url: string | null;
  currentValue: number | null;
  potentialValue: number | null;
  fetchValue(arg1: boolean): void;
  percentageValueText(): string;
  changeValueText(): string;
}

export default (
  fetch_url: unknown,
  currentValue: unknown,
  potentialValue: unknown,
): AlpineComponent<State> => ({
  fetch_url: typeof fetch_url === "string" ? fetch_url : null,
  fetching: false,
  currentValue: typeof currentValue === "number" ? currentValue : null,
  potentialValue: typeof potentialValue === "number" ? potentialValue : null,

  init() {
    if (!this.fetch_url || this.currentValue) {
      return;
    }
    this.fetching = true;
    setTimeout(() => {
      this.fetchValue(false);
    }, 2000);
  },

  async fetchValue(retry) {
    if (!this.fetch_url) {
      return;
    }
    const response = await call(this.fetch_url, {
      headers: {
        Accept: "application/json",
      },
    });
    if (response.ok) {
      const result = ValuationResponse.safeParse(response.value);
      if (result.success) {
        this.currentValue = result.data.current_property_value;
        this.potentialValue = result.data.potential_property_value;
        this.fetching = false;
      }
      if (!retry) {
        setTimeout(() => {
          this.fetchValue(true);
        }, 4000);
      } else {
        this.fetching = false;
      }
    } else {
      this.fetching = false;
    }
  },

  currentValueText() {
    if (!this.currentValue) {
      return "Unknown";
    }
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 0,
    }).format(this.currentValue);
  },

  potentialValueText() {
    if (!this.potentialValue) {
      return "Unknown";
    }
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 0,
    }).format(this.potentialValue);
  },

  percentageValueText() {
    if (!this.currentValue || !this.potentialValue) {
      return "";
    }
    const percentage =
      ((this.potentialValue - this.currentValue) / this.currentValue) * 100;
    return `${percentage.toFixed(0)}%`;
  },

  changeValueText() {
    if (!this.currentValue || !this.potentialValue) {
      return "Unknown";
    }
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 0,
    }).format(this.potentialValue - this.currentValue);
  },
});
