import { AlpineComponent } from "alpinejs";
import { z } from "zod";

const Model = z.object({
  version: z.object({
    dirty: z.number(),
  }),
});

type Model = z.infer<typeof Model>;

interface State extends Record<string | symbol, unknown> {
  load(): void;
}

export default (): AlpineComponent<State> => {
  return {
    load() {
      if (Elm === undefined) {
        return;
      }

      if (Elm["Main"] == undefined) {
        return;
      }

      const data = window.localStorage.getItem("model");

      const app = Elm["Main"].init({
        node: this.$el,
        // eslint-disable-next-line
        flags: data ? JSON.parse(data) : {},
      });

      app.ports["setLocalStorage"]?.subscribe?.((value) => {
        const result = Model.safeParse(value);

        if (!result.success) {
          return;
        }

        window.localStorage.setItem("model", JSON.stringify(value));

        app.ports["savedToLocalStorage"]?.send?.(result.data.version.dirty);
      });
    },
  };
};
