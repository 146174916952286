import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";

import "./dashboard/carbon";
import "./dashboard/cost";

// React Components
import "./react/suppliers/edit-plan-form";
import "./react/availability-picker";

// Alpine Components
import address from "./assessment/address";
import ajax from "./forms/ajax";
import assignee from "./assignee";
import calendar from "./components/calendar";
import carousel from "./components/carousel";
import datetimepicker from "./components/datetimepicker";
import elm from "./components/elm";
import gaugechart from "./components/gaugechart";
import map from "./components/map";
import event from "./tracking/event";
import file from "./forms/file";
import flexiblenav from "./components/flexiblenav";
import ga from "./tracking/ga";
import hubspot from "./tracking/hubspot";
import lightbox from "./components/lightbox";
import modal from "./components/modal";
import nav from "./components/nav";
import notfound from "./tracking/notfound";
import notifications from "./components/notifications";
import order from "./forms/order";
import pdf from "./components/pdf";
import planbasket from "./components/planbasket";
import posthog from "./tracking/posthog";
import price from "./price";
import rag from "./components/rag";
import radio from "./forms/radio";
import redirect from "./components/redirect";
import sidescroller from "./components/sidescroller";
import simplemap from "./components/simplemap";
import stripe from "./assessment/stripe";
import tooltip from "./components/tooltip";
import tracking from "./tracking/tracking";
import upload from "./components/upload";
import valuefetching from "./components/valuefetching";
import usage from "./components/usage";
import visibility from "./components/visibility";
import uploader from "./components/file/uploader";

Alpine.plugin(collapse);
Alpine.plugin(intersect);

Alpine.data("uploader", uploader);
Alpine.data("address", address);
Alpine.data("ajax", ajax);
Alpine.data("assignee", assignee);
Alpine.data("calendar", calendar);
Alpine.data("carousel", carousel);
Alpine.data("datetimepicker", datetimepicker);
Alpine.data("elm", elm);
Alpine.data("event", event);
Alpine.data("file", file);
Alpine.data("flexiblenav", flexiblenav);
Alpine.data("ga", ga);
Alpine.data("gaugechart", gaugechart);
Alpine.data("hubspot", hubspot);
Alpine.data("lightbox", lightbox);
Alpine.data("map", map);
Alpine.data("modal", modal);
Alpine.data("nav", nav);
Alpine.data("notfound", notfound);
Alpine.data("notifications", notifications);
Alpine.data("order", order);
Alpine.data("planbasket", planbasket);
Alpine.data("posthog", posthog);
Alpine.data("price", price);
Alpine.data("rag", rag);
Alpine.data("exportToPDF", pdf);
Alpine.data("radio", radio);
Alpine.data("redirect", redirect);
Alpine.data("sidescroller", sidescroller);
Alpine.data("simplemap", simplemap);
Alpine.data("stripe", stripe);
Alpine.data("tooltip", tooltip);
Alpine.data("tracking", tracking);
Alpine.data("upload", upload);
Alpine.data("valuefetching", valuefetching);
Alpine.data("usage", usage);
Alpine.data("visibility", visibility);

Alpine.start();
