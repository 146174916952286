import { render } from "preact";
import { useState } from "preact/hooks";

import { DateTime } from "luxon";

import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { z } from "zod";
import { ICON_CLASSNAMES } from "./components/constants";

type PickerDayProps = {
  day: DateTime;
  outsideCurrentMonth: boolean;
  today: boolean;
};

type Props = {
  leadtime: number;
  currentSlots: string;
};

const CurrentSlotsOptions = z.array(
  z.object({
    date: z.string().transform((str) => DateTime.fromISO(str)),
    am: z.boolean(),
    pm: z.boolean(),
  }),
);

type CurrentSlotsType = z.infer<typeof CurrentSlotsOptions>;

const formatDate = (date: DateTime) => date.toFormat("yyyy-MM-dd");

function AvailabilityPicker({ currentSlots, leadtime }: Props) {
  const [selectedDates, setSelectedDates] = useState<CurrentSlotsType>(
    CurrentSlotsOptions.parse(JSON.parse(currentSlots)) ?? [],
  );

  const toggleDate = (dateToToggle: DateTime) => {
    if (
      selectedDates.some(
        (date) => formatDate(date.date) === formatDate(dateToToggle),
      )
    ) {
      setSelectedDates(
        selectedDates.filter(
          (item) => formatDate(item.date) !== formatDate(dateToToggle),
        ),
      );
    } else {
      setSelectedDates([
        ...selectedDates,
        { date: dateToToggle, am: true, pm: true },
      ]);
    }
  };

  const removeDate = (dateToRemove: DateTime) => {
    setSelectedDates(
      selectedDates.filter((item) => item.date !== dateToRemove),
    );
  };

  const toggleTime = (dateToToggle: DateTime, time: string) => {
    const date = selectedDates.find(
      (date) => formatDate(date.date) === formatDate(dateToToggle),
    );

    if (date) {
      if (time === "am") {
        date.am = !date.am;
      } else {
        date.pm = !date.pm;
      }
      setSelectedDates([...selectedDates]);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-2">
        <div className="col-span-1">
          <DateCalendar
            className="rounded-lg border border-indigo-100"
            disablePast
            views={["day"]}
            slots={{
              day: (props: PickerDayProps) => (
                <PickersDay
                  selected={selectedDates.some(
                    (date) => formatDate(date.date) === formatDate(props.day),
                  )}
                  isFirstVisibleCell={false}
                  isLastVisibleCell={false}
                  outsideCurrentMonth={props.outsideCurrentMonth}
                  day={props.day}
                  onDaySelect={toggleDate}
                  today={props.today}
                  disabled={
                    props.day.weekday === 7 ||
                    props.day.weekday === 6 ||
                    DateTime.now().plus({ days: leadtime }) > props.day
                  }
                />
              ),
            }}
            slotProps={{
              calendarHeader: {
                className: "bg-gray-100 !p-5 !m-0 !mb-3",
              },
            }}
          />
        </div>
        <div className="col-span-1">
          <Table size="small" className="rounded-t-lg">
            <TableHead className="bg-gray-100 ">
              <TableRow>
                <TableCell>Selected Dates</TableCell>
                <TableCell align="center" className="text-white">
                  Time of Day{" "}
                  <span className="hidden sm:inline">(click to toggle)</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedDates.map((selectedDate) => (
                <TableRow
                  key={selectedDate.date}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <div className="flex items-center">
                      <DeleteIcon
                        className={ICON_CLASSNAMES}
                        onClick={() => removeDate(selectedDate.date)}
                      />
                      <span className="ml-2 text-sm sm:text-md">
                        {formatDate(selectedDate.date)}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="grid grid-cols-2 gap-x-2 sm:gap-x-6">
                      <Button
                        variant={selectedDate.am ? "contained" : "outlined"}
                        onClick={() => toggleTime(selectedDate.date, "am")}
                        size="small"
                      >
                        AM
                      </Button>
                      <Button
                        variant={selectedDate.pm ? "contained" : "outlined"}
                        onClick={() => toggleTime(selectedDate.date, "pm")}
                        size="small"
                      >
                        PM
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      {selectedDates.map((selectedDate) => (
        <input
          type="hidden"
          name="availability"
          value={`${formatDate(selectedDate.date)} ${
            selectedDate.am ? "-AM" : ""
          } ${selectedDate.pm ? "-PM" : ""} ${
            !selectedDate.am && !selectedDate.pm ? "-AM-PM" : ""
          }  `}
        />
      ))}
    </LocalizationProvider>
  );
}

const root = document.getElementById("availability-picker");

if (root) {
  render(
    <AvailabilityPicker
      leadtime={parseInt(root.dataset["leadtime"] ?? "7", 10)}
      currentSlots={root.dataset["currentslots"] ?? ""}
    />,
    root,
  );
}
